<template>
  <vx-card class="emailCard">
    <h2 class="mb-6 mt-2">Email Details</h2>
    <vs-form>
      <vs-label class="label" >Subject Line</vs-label>
      <vs-input
        class="col-sm-3"
        v-model="emailData.subject"
        name="subject"
        v-validate="'required|alpha_spaces|max:150'"
        data-vv-as="Subject"
      />
      <span class="text-danger text-sm" v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
    </vs-form>
    <div class="editEmail">
      <h5>Email Content</h5>
    </div>
    <vs-label class="label" >Available Linked Content</vs-label>
    <div class="linkedContent">
      <ul>
        <li  class="keywords" v-if="emailData.slug==='account-created' "><span v-pre>password</span></li>
        <li  class="keywords" v-if="emailData.slug ==='forgot-password' || emailData.slug==='reset-password' "><span v-pre>link</span></li>
        <!--<li  class="keywords" v-if="emailData.slug==='forgot-password' || pages.slug==='reset-password'"><span v-pre>lastName</span></li>
        <li  class="keywords" ><span v-pre>projectName</span></li>
        <li  class="keywords" v-if="pages.slug ==='forgot-password' && pages.slug !=='account-approved' && pages.slug !=='franchise-nurse-registration'"><span v-pre>link</span></li>-->
      </ul>
    </div>
    <div class="editEmail">
      <vs-label class="label"> Body Content</vs-label>
      <quillEditor :pages="emailData"></quillEditor>
    </div>
    <div slot="footer" class="mb-15 mt-0 ml-3">
      <vs-row>
        <vs-button @click="saveEmailDetails" align="left">Save Details</vs-button>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>

  import { mapActions } from 'vuex';
  import quillEditor from '../../../../Editor';

  export default {
    name: 'emailViewEditDetail',
    components: {
      quillEditor
    },
    data:()=>({
      emailData:{
        content:'',
        subject:''
      },

    }),
    methods:{
      ...mapActions('email', [
        'fetchEmailDetails',
        'updateEmailDetails'
      ]),
      getEmailDetail(id) {
        this.fetchEmailDetails(id).then(res => {
          this.emailData.content = res.data.data.content
          this.emailData.subject= res.data.data.subject
          this.emailData=res.data.data
        })
      },
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: color
        })
      },
      async saveEmailDetails() {
        await this.$validator.validateAll().then(result => {
          if (result) {
            let id = this.$route.params.id
            let info = this.emailData
            this.updateEmailDetails({id, info}).then(res => {
              this.showMessage("Success", "Email template edited successfully.", "success");
            })
            this.$router.push({name: 'email-template-list'})
          }
        })
      },
    },
    created() {
      let pageId = this.$route.params.id;
      this.getEmailDetail(pageId);
    }
  }
</script>
<style>
  ul{
    list-style: none;
    margin-left:0px;
  }
  .keywords{
    display: inline-block;
    border: 1px solid  rgba(40, 154, 213,1);
    margin-right:3px;
    padding:8px;
    color: white;
    border-radius: 10px;
    background-color:  rgba(40, 154, 213,1);
  }
  .label {
    font-size: small;
    color: black;
  }
  .editEmail{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .emailCard{
    min-height: 650px;
  }
</style>
